import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private showSnackBarSubject = new BehaviorSubject<boolean>(false);
  private typeSubject = new BehaviorSubject<string>('success');
  private titleSubject = new BehaviorSubject<string>('');
  private messageSubject = new BehaviorSubject<string>('');

  showSnackBar$ = this.showSnackBarSubject.asObservable();
  type$ = this.typeSubject.asObservable();
  title$ = this.titleSubject.asObservable();
  message$ = this.messageSubject.asObservable();

  displaySnackBar(type: string, title: string, message: string) {
    this.typeSubject.next(type);
    this.titleSubject.next(title);
    this.messageSubject.next(message);
    this.showSnackBarSubject.next(true);

    setTimeout(() => {
      this.showSnackBarSubject.next(false);
    }, 6000);
  }

  hideSnackBar() {
    this.showSnackBarSubject.next(false);
  }
}